import React, { useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import {
  CustomCarousel,
  TooltipDisclaimer,
} from '~/common/components/ui-elements';
import { colorBlack, desktopMin, tabletMax } from '~/common/utils';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';

interface IFeatureWalkProps {
  id?: number;
  rowTileCount?: number;
  tiles?: IFeatureWalk[];
  backgroundColor?: string;
  arrowColor?: string;
  tileBackgroundColor?: string;
  tileFontColor?: string;
}

interface IFeatureWalk {
  header?: string;
  headerDisclaimer?: string;
  imageSrc?: string;
  imageDisclaimer?: string;
  imageDisclaimerFontColor?: string;
  content?: string;
}

const breakpoints = {
  desktop: {
    breakpoint: { max: 4000, min: desktopMin },
    items: 3,
  },
  mobile: {
    breakpoint: { max: tabletMax, min: 0 },
    items: 1,
  },
};

export const FeatureWalk: React.FC<IFeatureWalkProps> = ({
  id,
  tiles,
  rowTileCount,
  backgroundColor,
  arrowColor,
  tileBackgroundColor,
  tileFontColor,
}) => {
  const _rowTileCount = rowTileCount || 3;

  const [isMobile, setIsMobile] = useState(
    useMediaQuery(
      { query: `(max-width: ${tabletMax}px)` },
      undefined,

      (matches) => {
        setIsMobile(matches);
      }
    )
  );
  useRTETooltipPositionCorrector(isMobile);

  const showArrows = () => {
    if (!(tiles && tiles.length > 0)) return false;
    return tiles.length > _rowTileCount;
  };

  const renderTiles = () => {
    const renderDisclaimer = (tile) => {
      if (!tile.imageDisclaimer) return <></>;
      return (
        <TooltipDisclaimer className={cn(styles.ImageDisclaimer)}>
          <p
            dangerouslySetInnerHTML={{
              __html: tile.imageDisclaimer as string,
            }}
            style={{ color: `#${tile.imageDisclaimerFontColor || 'ffffff'}` }}
          />
        </TooltipDisclaimer>
      );
    };

    if (!(tiles && tiles.length > 0)) return <></>;
    return tiles.map((tile, idx) => {
      return (
        <div
          key={idx}
          className={styles.FeatureWalk}
          style={{
            ...(isMobile ? { flexBasis: '100%' } : {}),
          }}
        >
          {tile.imageSrc && (
            <div className={styles.ImageContainer}>
              <img src={tile.imageSrc} loading="lazy" />
              {renderDisclaimer(tile)}
            </div>
          )}
          {(tile.content || tile.header) && (
            <div
              style={{
                ...(tileBackgroundColor
                  ? { backgroundColor: `#${tileBackgroundColor}` }
                  : {}),
                ...(tileFontColor ? { color: `#${tileFontColor}` } : {}),
              }}
              className={styles.ContentContainer}
            >
              <div>
                {tile.header && (
                  <h5
                    dangerouslySetInnerHTML={{ __html: tile.header as string }}
                  />
                )}
                {tile.headerDisclaimer && (
                  <TooltipDisclaimer
                    followCursor
                    disclaimer={tile.headerDisclaimer}
                    className={styles.DisclaimerContainer}
                    tooltipClassName={styles.DisclaimerTooltip}
                    contentClassName={styles.DisclaimerContent}
                    color={
                      tileFontColor !== colorBlack ? tileFontColor : undefined
                    }
                  />
                )}
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: tile.content as string }}
              />
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div
      key={id}
      id={`cid-${id}`}
      className={cn('full-device-width', styles.FeatureWalkContainer)}
      style={backgroundColor ? { backgroundColor: `#${backgroundColor}` } : {}}
    >
      <MediaQuery minWidth={0}>
        <div
          className={cn(styles.FeatureWalkGroup, {
            [styles.FeatureWalkCarousel]: !isMobile,
            [styles.FeatureWalkCarouselMobile]: isMobile,
          })}
        >
          {isMobile ? (
            renderTiles()
          ) : (
            <CustomCarousel
              carouselArrowColor={arrowColor}
              responsive={{
                ...breakpoints,
                desktop: { ...breakpoints.desktop, items: _rowTileCount || 3 },
              }}
              removeArrowOnDeviceType={['mobile']}
              arrows={showArrows()}
            >
              {renderTiles()}
            </CustomCarousel>
          )}
        </div>
      </MediaQuery>
    </div>
  );
};
